<template>
    <modal ref="modalCalificarTema" titulo="Calificar Tema" adicional="Enviar" :desactivar-guardar="false" :no-aceptar="true" tamano="modal-lg" @adicional="calificarTema">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col-auto" />
                <div class="col-auto" />
                <div class="col">
                    <div class="row mx-0 my-4">
                        <div class="col text-center f-18">
                            De 1 a 5 siendo 5 la nota mayor califique el nivel de satisfacción con el tema.
                        </div>
                    </div>
                    <div class="row j-center mx-0 my-4 py-4">
                        <ValidationProvider v-slot="{errors}" rules="required" name="calificación">
                            <div class="d-flex flex-column">
                                <el-radio-group v-model=" model.calificacion ">
                                    <el-radio class="radio-absolute" :label="1" />
                                    <el-radio class="radio-absolute" :label="2" />
                                    <el-radio class="radio-absolute" :label="3" />
                                    <el-radio class="radio-absolute" :label="4" />
                                    <el-radio class="radio-absolute" :label="5" />
                                </el-radio-group>
                                <span class="text-danger w-100 f-11 mt-2"> {{ errors[0] }} </span>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div class="row mx-0 mb-4">
                        <div class="col-12 pl-4 text-gris f-18 my-3">
                            ¿Tiene algún comentario sobre este tema?
                        </div>
                        <div class="col-12">
                            <ValidationProvider v-slot="{errors}" rules="max:500" name="comentario">
                                <el-input v-model="model.calificacion_comentario" type="textarea" :rows="4" class="area-radius" placeholder="Texto.." maxlength="500" show-word-limit /> 
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-auto" />
                <div class="col-auto" />
                <div class="col-auto" />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Curso from "../../../services/cursos/ver_cursos";

export default {
    data(){
        return{
            model: {
                calificacion:5  ,
                calificacion_comentario: '',
                id_tema: null,
                created_by: null
            },
        }
    },
    methods: {
        toggle(model){
            if(model.calificacion === undefined || null){
                model ={
                    ...model,
                    calificacion: 5
                }
            }
            this.model = { ...model } 
            console.log(model)
            this.$refs.modalCalificarTema.toggle();
        },
        async calificarTema(){
            try {                
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const { data: { data, message } } = await Curso.calificarTema(this.model)
                this.$emit('mandar-calificacion',data.temaUser)
                this.notificacion('Exito!', message, 'success')
                this.$refs.modalCalificarTema.toggle();

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
</style>